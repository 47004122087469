import { graphql } from 'gatsby'
import GatsbyHead from '../components/GatsbyHead'
import SampleExampleContent from '../components/SampleExampleContent'

export const query = graphql`
  query CVExamples($contentful_id: String!, $locale: String!) {
    contentfulReactPage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      id
      contentful_id
      slug
      node_locale
      languages
      meta_title
      meta_description
      sections {
        ...PageSection
      }
    }
    allCategories: allContentfulCvExampleCategory(
      filter: { node_locale: { eq: $locale }, slug: { nin: [null, ""] }, name: { nin: [null, ""] } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          slug
          title: name
          cvs: cv_examples {
            id
            title
            image: cv_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 250, height: 353, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    popularCategories: allContentfulCvExampleCategory(
      filter: {
        node_locale: { eq: $locale }
        slug: { nin: [null, ""] }
        name: { nin: [null, ""] }
        popular: { eq: true }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          title: name
          cvs: cv_examples {
            id
            title
            image: cv_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 250, height: 353, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    industries: allContentfulCvExampleIndustry(
      filter: { node_locale: { eq: $locale }, contentful_id: { ne: "7BAllZgKrAidhQiwAMXbBG" } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          id
          title
          cvCategories: cv_example_categories {
            id
            slug
            title: name
            cvs: cv_examples {
              id
              title
              image: cv_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 250, height: 353, layout: FIXED)
                  }
                }
              }
            }
          }
        }
      }
    }
    banner: contentfulComponentTextImage(contentful_id: { eq: "IHUsOmn6MjM9L0ciuZo8r" }, node_locale: { eq: $locale }) {
      ...ComponentTextImage
    }
  }
`

const CVExamples = ({ pageContext, data }) => {
  return (
    <SampleExampleContent
      data={data}
      pageContext={pageContext}
    />
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, translatedPages },
  data: {
    contentfulReactPage: { meta_title, meta_description },
  },
}) => {
  return (
    <GatsbyHead
      pageTitle={meta_title}
      metaDescription={meta_description}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
    />
  )
}

export default CVExamples
